*{
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
}
.part1{
    background: linear-gradient(188deg, #0085FF -6.28%, #2832E4 30.34%, #0D0A96 75.08%);
    flex:.2;
}
.part1-img-container{
    padding-top:152px;
    padding-left:43px;
    padding-right:71px;
    padding-bottom:40px;
}
